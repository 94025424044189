import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { getAxios, postAxiosWithToken } from "../../../../service/apiservice";
import { dischargeDetailsListEndPoint, entryDropdownListEndPoint, procedureCreateEndpoint, procedureDropdownListEndPoint } from "../../../../service/api";

export default function Dischargedetails({ closeModal,getData }) {
  const [statusData, setStatusData] = useState([]);
  const [formMode, setFormMode] = useState("update");
  const [entryTypeDropdown, setentryTypeDropdown] = useState([]);
  const [procedureTypeDropdown, setProcedureTypeDropdown] = useState([]);
  const [currentdetails, setCurrentdetails] = useState({});
  const [patientData, setPatientData] = useState({});
  const [entry_type, setEntrytypes] = useState("");

  const navigate = useNavigate();


  const schema = yup
    .object({

      discharge_remarks: yup.string(),
      remarks: yup.string(),
      discharge_date: yup.string().required(),
      entry_type: yup.string(),
      procedure_type: yup.string(),
      admission_date: yup.string(),
      // procedure_date: yup.string(),
      procedure_reason: yup.string()
    })
    .required()


  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    values:
      formMode === "update"
        ? {
          discharge_remarks: currentdetails?.discharge_remarks,
          remarks: currentdetails?.remarks,
          discharge_date: currentdetails?.discharge_date,
          entry_type: entry_type,
          procedure_type: currentdetails?.procedure_type,
          admission_date: currentdetails?.admission_date,
          // procedure_date: currentdetails?.procedure_date,
          procedure_reason: currentdetails?.procedure_reason,
          name: patientData?.name,
          phone: patientData?.phone,
          // source_type: "1",
        }
        : {
          discharge_date: "",
          entry_type: "",
          procedure_type: "",
          admission_date: "",
          // procedure_date: "",
          procedure_reason: "",
          discharge_remarks: "",
          remarks: "",
          name: "",
          phone: "",
          // source_type: "",
        },
  });

  useEffect(() => {
    EntryDropdownList();
    ProcedureTypeDropdownList();
    getDischargeDetails();

  }, [])

  const EntryDropdownList = async () => {
    var response = await getAxios({
      url: entryDropdownListEndPoint,
    });

    if (response !== null) {
      setentryTypeDropdown(response.data.list)
    }
  }



  const ProcedureTypeDropdownList = async () => {
    var response = await getAxios({
      url: procedureDropdownListEndPoint,
    });

    if (response !== null) {
      setProcedureTypeDropdown(response.data.list)
    }
  }

  const onSubmit = (data) => {
    console.log("data",data)
    
    dischargepatient(data);
    closeModal()
  };

  const dischargepatient = async (body) => {
    var response = await postAxiosWithToken({
      url: dischargeDetailsListEndPoint + localStorage.getItem('discharge_form_id') + "/",
      body: body
    })

    if (response != null) {
      getData()
      clearData()
      localStorage.removeItem('discharge_form_id')
      toast.success(response.message)
      navigate('/procedure/in-patient')
    }
  }

  const [selectedEntryType, setSelectedEntryType] = useState('');

  const handleEntryTypeChange = (e) => {
    setSelectedEntryType(e.target.value);
  };

  const getDischargeDetails = async () => {
    var id = localStorage.getItem("discharge_form_id");

    var response = await getAxios({
      url: procedureCreateEndpoint + id + "/",
    });

    if (response !== null) {
      setEntrytypes(response.data.procedure_list?.entry_type)
      var tempDetails = {
        entry_type: response.data.procedure_list?.entry_type,
        procedure_type: response.data.procedure_list?.procedure_type,
        admission_date: response.data.procedure_list?.in_date,
        // procedure_date: response.data.procedure_list?.procedure_date,
        procedure_reason: response.data.procedure_list?.procedure_reason,
        remarks: response.data.procedure_list?.remarks,
        ip_no: response.data.procedure_list?.ip_no,
        name: response.data.personal_list?.name,
        phone: response.data.personal_list?.phone,
      };
      setCurrentdetails(tempDetails);
      setSelectedEntryType(response.data.procedure_list?.entry_type,)
      setPatientData(response.data.personal_list);
    }
  };

  const clearData = () => {
    reset()
    setCurrentdetails({
      patient_id: "",
      procedure_reason: "",
      remarks: "",
      procedure_type: "",
      // procedure_date: "",
      admission_date: "",
      ip_no: "",
      entry_type: ""
    })
  }

  //today date


  const [defaultDate, setDefaultDate] = useState(getToday());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDefaultDate(getToday());
    }, 24 * 60 * 60 * 1000); // Update every 24 hours

    return () => clearInterval(intervalId);
  }, []);

  function getToday() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full h-full p-[25px]">
          <div className="grid xl:grid-cols-2 sm:grid-cols-2 gap-5 mt-5">
          <div className="my-[5px]">
              <p className="mb-[5px] text-[15px]">
              Patient Name
              </p>
              <input
                type="text"
                placeholder="Procedure name"
                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                name="name"
                {...register('name')}
                disabled
              />
            </div>
            <div className="my-[5px]">
              <p className="mb-[5px] text-[15px]">
                Mobile Number
              </p>
              <input
                type="number"
                placeholder="mobile number"
                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                name="phone"
                {...register('phone')}
                disabled
              />
            </div>
            <div className="sm:my-[5px] ">
              <p className="mb-[5px] text-[15px]">
                Patient Entry Type
              </p>
              <select
                placeholder="fees type"
                className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                name="entry_type"
                {...register('entry_type')}
                onChange={handleEntryTypeChange}
                value={selectedEntryType}
                disabled
              >
                <option value={""}>--Select Entry Type--</option>
                {
                  entryTypeDropdown.map((option) => (
                    <option className="capitalize" key={"entry_name" + option.entry_name} value={option.id}>{option.entry_name}</option>
                  ))
                }
              </select>
            </div>
            <div className="sm:my-[5px] ">
              <p className="mb-[5px] text-[15px]">
                Procedure Type
              </p>
              <select
                placeholder="fees type"
                className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                name="procedure_type"
                {...register('procedure_type')}
                disabled
              >
                <option value={""}>--select Procedure Type--</option>
                {
                  procedureTypeDropdown.map((option) => (
                    <option className="capitalize" key={"procedure_name" + option.procedure_name} value={option.id}>{option.procedure_name}</option>
                  ))
                }
              </select>
            </div>
            <div className="my-[5px]">
              <p className="mb-[5px] text-[15px]">
                Admission Date 
              </p>
              <input
                type="date"
                placeholder="Admission Date "
                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                name="admission_date"
                {...register('admission_date')}
                disabled
              />
            </div>
            {/* <div className="my-[5px]">
              <p className="mb-[5px] text-[15px]">
                Procedure Date 
              </p>
              <input
                type="date"
                placeholder="Procedure Date "
                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                name="procedure_date"
                {...register('procedure_date')}
                readOnly
              />
            </div> */}
            <div className="my-[5px]">
              <p className="mb-[5px] text-[15px]">
                Procedure Reason
              </p>
              <input
                type="text"
                placeholder="Procedure Reason"
                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                name="procedure_reason"
                {...register('procedure_reason')}
                disabled
              />
             
            </div>
          </div>
          <div className="grid xl:grid-cols-1 sm:grid-cols-1">
            <p className="mt-5 text-black text-[14px] font-500">
              <label>Remark(admission)</label>
            </p>
            <textarea
              className="mt-5 border-2 border-black-500 outline-none p-1"
              id="remarks_admission"
              name="remarks"
              rows="4"
              cols="50"
              {...register("remarks")}
              disabled
            ></textarea>

           
          </div>
          <div className='border border-gray-300 mt-[25px]'></div>

          <div className="mt-[20px] grid xl:grid-cols-2 sm:grid-cols-2">
            <div>
              <p className="mb-[5px] text-[15px]">
                Discharge Date <span className="text-red-500">*</span>
              </p>

              <div className="flex items-center">
                <input
                  type="date"
                  name="discharge_date"
                  className="sm:mb-[5px] border border-gray-300 w-full rounded-lg p-[10px] text-[17px] outline-none"
                  {...register("discharge_date")}
                  defaultValue={defaultDate}
                />
              </div>
              <p className="text-red-500 font-[400] text-[13px]">
                {errors.discharge_date?.message}
              </p>
            </div>
          </div>

          <div className="grid xl:grid-cols-1 sm:grid-cols-1">
            <p className="mt-5 text-black text-[14px] font-500">
              <label>Remark(discharge)</label>
            </p>
            <textarea
              className="mt-5 border-2 border-black-500 outline-none p-1"
              id="discharge_remarks"
              name="discharge_remarks"
              rows="4"
              cols="50"
              {...register("discharge_remarks")}
            ></textarea>

           
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-primary text-white px-[10px] py-[5px] rounded-[8px] mt-4"
            >
              Submit
            </button>
          </div>
        </div>
      </form>

    </div>
  );
}
