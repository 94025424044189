import React, { useEffect, useState } from "react";
import {
  notificationChangeApproveEndPoint,
  notificationListDataEndPoint,
  notificationStatusDropdownEndPoint,
  notificationStatusListDataEndPoint,
  sorceTypeListEndPoint,
} from "../../service/api";
import { getAxios, putAxiosWithToken } from "../../service/apiservice";
import toast from "react-hot-toast";
import { setNotifications } from "../../redux/commonslice";
import { useDispatch } from "react-redux";

function ViewForm({ onClose, currentstatus, getData }) {
  const [statusData, setStatusData] = useState({});
  const [statusDropdownData, setStatusDropdownData] = useState([]);
  const [sourceTypeDropdown, setSourceTypeDropdown] = useState([]);
  const [errors, setErrors] = useState(null);
  console.log("statusData", statusData.existing_lead_type);
  const dispatch = useDispatch();

  useEffect(() => {
    getallNotificationDetails();
    getallDropdownDetails();
    sourceTypeDropdownList();
  }, []);

  const getallNotificationDetails = async () => {
    var response = await getAxios({
      url: notificationStatusListDataEndPoint + currentstatus.id + "/",
    });

    if (response !== null) {
      var tempDetails = {
        existing_lead_type: response.data?.existing_lead_type,
        existing_source_type: response.data?.existing_source_type,
        new_lead_type: response.data?.new_lead_type,
        new_source_type: response.data?.new_source_type,
        patient_name: response.data?.patient_name,
        mobile: response.data?.mobile,
        paitent_name: response.data?.paitent_name,
        approve_source_type: response.data?.new_source_type_id,
      };
      setStatusData(tempDetails);
    }
  };

  const getallDropdownDetails = async () => {
    var response = await getAxios({
      url: notificationStatusDropdownEndPoint,
    });

    if (response !== null) {
      setStatusDropdownData(response.data.list);
    }
  };

  const sourceTypeDropdownList = async () => {
    var response = await getAxios({
      url: sorceTypeListEndPoint,
    });

    if (response !== null) {
      setSourceTypeDropdown(response.data.list);
    }
  };
  const onValueUpdate = (e) => {
    const { name, value } = e.target;
    
    setStatusData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    
  
    // Handle error messages dynamically
    setErrors((prevState) => ({
      ...prevState,
      [name]: value.length === 0 ? `${name} field is required` : "",
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const formFields = new FormData(e.target);
    const formDataObject = Object.fromEntries(formFields.entries());


    const isValidForm = (formDataObject) => {
      const err = {};
      Object.entries(formDataObject).forEach(([field, value]) => {
        if (value.length === 0) {
          err[field] = `${field} field is required`;
        } else {
          err[field] = "";
        }
      });
  
     
  
      setErrors((prevState) => ({
        ...prevState,
        ...err,
      }));
  
      const hasErrors = Object.values(err).some((error) => error.length > 0);
  
      return !hasErrors;
    };

    if (isValidForm(formDataObject)) {
      console.log("formDataObject",formDataObject)
      // if (formMode === "update") {
      //   statusData["transfer"] = isChecked
      updatenotification(formDataObject);

      // }
    } else {
      console.log("Form has validation errors. Please correct them.");
    }
  };

  const updatenotification = async (body) => {
    var response = await putAxiosWithToken({
      url: notificationChangeApproveEndPoint + currentstatus.id + "/",
      body: body,
    });

    if (response != null) {
      getData();
      onClose();
      getnotificationownData();
      toast.success(response.message);
    }
  };



const getnotificationownData = async () => {
    var response = await getAxios({
      url: notificationListDataEndPoint
    });

    if (response !== null) {
      dispatch(setNotifications(response))
    }
  };

  return (
    <div>

    <form onSubmit={onSubmit}>
      <div className="flex gap-[10px] mt-4">
      <div className="sm:my-[5px] w-full">
          <p className="mb-[5px] text-[15px]">Patient Name</p>
          <input
            type="text"
            name="paitent_name"
            disabled
            id="paitent_name"
            placeholder="paitent_name"
            className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
            value={statusData.paitent_name}
            // onChange={onValueUpdate}
          />
        </div>
        

      <div className="sm:my-[5px] w-full">
          <p className="mb-[5px] text-[15px]">Mobile</p>
          <input
            type="text"
            name="mobile"
            disabled
            id="mobile"
            placeholder="mobile"
            className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
            value={statusData.mobile}
            // onChange={onValueUpdate}
          />
        </div>
      </div>
      <div className="flex gap-[10px] mt-4">
        <div className="sm:my-[5px] w-full">
          <p className="mb-[5px] text-[15px]">Existing Lead Type</p>
          <input
            type="text"
            name="existing_lead_type"
            id="existing_lead_type"
            placeholder=" existing_lead_type"
            className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
            value={statusData.existing_lead_type}
            disabled
            // onChange={onValueUpdate}
          />
          {/* <p style={{ color: 'red' }}>{errors?.mobile}</p> */}
        </div>

        <div className="sm:my-[5px] w-full">
          <p className="mb-[5px] text-[15px]">Existing Source Type</p>
          <input
            type="text"
            name="existing_source_type"
            disabled
            id="existing_source_type"
            placeholder="existing_source_type"
            className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
            value={statusData.existing_source_type}
            // onChange={onValueUpdate}
          />
        </div>
      </div>

      <div className="flex gap-[10px] mt-4">
        <div className="sm:my-[5px] w-full">
          <p className="mb-[5px] text-[15px]">New Lead Type</p>
          <input
            type="text"
            name="new_lead_type"
            id="new_lead_type"
            placeholder=" new_lead_type"
            className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
            value={statusData.new_lead_type}
            disabled
            // onChange={onValueUpdate}
          />
          {/* <p style={{ color: 'red' }}>{errors?.mobile}</p> */}
        </div>

        <div className="sm:my-[5px] w-full">
          <p className="mb-[5px] text-[15px]">New Source Type</p>
          <input
            type="text"
            name="new_source_type"
            disabled
            id="new_source_type"
            placeholder="new_source_type"
            className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
            value={statusData.new_source_type}
            // onChange={onValueUpdate}
          />
        </div>
      </div>

      <div className="flex gap-[10px] mt-4">
        <div className="sm:my-[5px] w-full">
        <p className="mb-[5px] text-[15px]">Status</p>
          <select
            placeholder="Source"
            className="sm:mb-[5px] h-[40px] w-full capitalize bg-white border border-gray-300 rounded-lg  p-[8px] text-[15px] outline-none"
            name="status"
           
          >
            <option value={""}>--Select Status --</option>
            {statusDropdownData.map((option) => (
              <option key={option.id} value={option.id} className="capitalize">
                {option.status_name}
              </option>
            ))}
          </select>
          <p style={{ color: 'red' }}>{errors?.status}</p>
        </div>
        <div className="sm:my-[5px] w-full">
        <p className="mb-[5px] text-[15px]">Verify Source Type</p>
        <select
          placeholder="Source"
          className="sm:mb-[5px] h-[40px] w-full capitalize bg-white border border-gray-300 rounded-lg p-[8px] text-[15px] outline-none"
          name="approve_source_type"
          // value={statusData.approve_source_type || ""} // Ensure it's controlled
          onChange={onValueUpdate}
        >
          <option value="0">--Select Source Type --</option>
          {sourceTypeDropdown.map((option) => (
            <option key={option.id} value={option.id} className="capitalize">
              {option.source_name}
            </option>
          ))}
        </select>
        <p style={{ color: 'red' }}>{errors?.approve_source_type}</p>
      </div>

      </div>
      <div className="sm:my-[5px] ">
              <p className="mb-[5px] text-[15px]">Remarks</p>
              <textarea
                className="border-2 border-black-500 rounded-[4px] w-full"
                name="remarks"
                id=""
                cols="50"
                rows="3"
              ></textarea>  

<p style={{ color: 'red' }}>{errors?.remarks}</p>
      </div>

      <div className="flex gap-[10px]">
            <button
              type="submit"
              className="bg-primary text-white px-[10px] py-[5px] rounded-[8px] mt-4 cursor-pointer"
            >
              SAVE
            </button>
            <div
              onClick={onClose}
              className="bg-primary text-white px-[10px] py-[5px] rounded-[8px] mt-4 cursor-pointer "
            >
              CLOSE
            </div>
          </div>
    </form>
    </div>
  );
}

export default ViewForm;
